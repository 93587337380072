import type { HasChildren } from '@eppendorf/vnls-react-components';
import { createContext, useContext, useMemo, useCallback } from 'react';

import { useFeatureToggles, type FeatureToggle } from '$hooks/use-feature-toggles.hook';

interface FeatureTogglesContextValue {
  isFeatureEnabled: (feature: FeatureToggle) => boolean;
}

const FeatureTogglesContext = createContext<FeatureTogglesContextValue | undefined>(undefined);

// Feature toggles logic can be removed if there are no future use cases.
// Alternatively, the API request for feature toggles can be commented out to keep the logic but avoid sending the network request.
export function FeatureTogglesProvider({ children }: HasChildren) {
  const featureToggles = useFeatureToggles();
  // eslint-disable-next-line security/detect-object-injection -- no user input
  const isFeatureEnabled = useCallback((feature: FeatureToggle) => featureToggles[feature] ?? false, [featureToggles]);
  const value = useMemo(() => ({ isFeatureEnabled }), [isFeatureEnabled]);

  return (
    <FeatureTogglesContext.Provider value={value}>
      {children}
    </FeatureTogglesContext.Provider>
  );
};

export const useFeatureTogglesContext = (): FeatureTogglesContextValue => {
  const context = useContext(FeatureTogglesContext);

  if (!context) {
    throw new Error('useFeatureTogglesContext must be used within a FeatureTogglesProvider');
  }

  return context;
};