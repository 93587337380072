import type {
	AddOrganizationDto,
	GetOrganizationDto,
	OrganizationWithDevices,
	PaginatedResponse,
	UpdateOrganizationDto,
	User,
} from "@eppendorf/vnls-user-tenant-utils";
import {
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import type { AxiosError, AxiosResponse } from "axios";

import { fetcher } from "$shared/fetch";
import {
	createPathWithQueryParams,
	MIGRATION_BASE_PATH,
	objectToURLSearchParams,
	ORGANIZATIONS_YGGDRASIL_BASE_PATH,
} from "$shared/utils/api-paths";

export const organizationQueryKey = "organizations";

export const useOrganizationsWithQueryParams = (queryParams = {}) => {
	const queryKey = [
		organizationQueryKey,
		...objectToURLSearchParams(queryParams).values(),
	];

	return useQuery(
		queryKey,
		async () => fetcher.get<PaginatedResponse<OrganizationWithDevices>>(
				createPathWithQueryParams(
					ORGANIZATIONS_YGGDRASIL_BASE_PATH,
					queryParams,
				),
			),
		{
			keepPreviousData: true,
		},
	);
};

export const useGetOrganization = (id: string) => {
	const queryKey = [organizationQueryKey, id];

	return useQuery(
		queryKey,
		async () => fetcher.get<GetOrganizationDto>(`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}`),
		{
			enabled: !!id,
		},
	);
};

export const useAddOrganization = () => {
	const queryClient = useQueryClient();
	const addOrganization = async (organization: AddOrganizationDto) => fetcher.post<AddOrganizationDto, GetOrganizationDto>(
			ORGANIZATIONS_YGGDRASIL_BASE_PATH,
			organization,
		);

	return useMutation<
		AxiosResponse<GetOrganizationDto>,
		AxiosError,
		AddOrganizationDto
	>(addOrganization, {
		onSuccess: () => {
			queryClient.invalidateQueries([organizationQueryKey]);
		},
	});
};

export const useUpdateOrganization = () => {
	const queryClient = useQueryClient();
	const updateOrganization = async (
		organization: UpdateOrganizationDto & Pick<GetOrganizationDto, "id">,
	) => {
		const { id, ...organizationWithoutId } = organization;
		return fetcher.patch<UpdateOrganizationDto, GetOrganizationDto>(
			`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}`,
			organizationWithoutId,
		);
	};

	return useMutation<
		GetOrganizationDto,
		AxiosError,
		UpdateOrganizationDto & Pick<GetOrganizationDto, "id">
	>(updateOrganization, {
		onSuccess: (data) => {
			queryClient.invalidateQueries([organizationQueryKey]);

			queryClient.setQueryData([organizationQueryKey, data.id], data);
		},
	});
};

export const useDeleteOrganization = () => {
	const queryClient = useQueryClient();
	const deleteOrganization = async (id: string) => fetcher.delete(`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}`);
	return useMutation<AxiosResponse, AxiosError, string>(deleteOrganization, {
		onSuccess: () => {
			queryClient.invalidateQueries([organizationQueryKey]);
		},
	});
};

export const useMigrateOrganizations = () => {
	const migrateOrganizations = (legacyTenantId: string) =>
		fetcher.post<unknown, { tenantId: string }>(
			`${MIGRATION_BASE_PATH}/migration/migrateTenant`,
			{
				legacyTenantId,
			},
		);
	const queryClient = useQueryClient();
	return useMutation<AxiosResponse, AxiosError, string>(migrateOrganizations, {
		onSuccess: () => {
			queryClient.invalidateQueries([organizationQueryKey]);
		},
	});
};

export const useResendInvite = () => {
	const queryClient = useQueryClient();
	const updateInviteSendAt = async (id: string) => fetcher.post<unknown, GetOrganizationDto>(
			`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}/actions/resend-invite`,
			undefined,
		);

	return useMutation<AxiosResponse<GetOrganizationDto>, AxiosError, string>(
		updateInviteSendAt,
		{
			onSuccess: ({ data }) => {
				queryClient.invalidateQueries([organizationQueryKey]);

				queryClient.setQueryData([organizationQueryKey, data.id], data);
			},
			onError: (error) => {
				// eslint-disable-next-line no-console -- for debugging
				console.log(error);
			},
		},
	);
};

export const useInviteUsers = () => {
	const inviteAllUsers = (legacyTenantId: string) =>
		fetcher.post<unknown, { tenantId: string }>(
			`${MIGRATION_BASE_PATH}/migration/invite-all-users`,
			{
				legacyTenantId,
			},
		);

	const queryClient = useQueryClient();
	return useMutation<AxiosResponse<{ tenantId: string }>, AxiosError, string>(
		inviteAllUsers,
		{
			onSuccess: ({ data }) => {
				queryClient.invalidateQueries([organizationQueryKey]);

				queryClient.setQueryData([organizationQueryKey, data.tenantId], data);
			},
			onError: (error) => {
				// eslint-disable-next-line no-console -- for debugging
				console.log(error);
			},
		},
	);
};

export const downloadMigrationReport = async (legacyTenantId: string) => {
	try {
		return await fetcher.get<{ url: string }>(
			`${MIGRATION_BASE_PATH}/migration/report/${legacyTenantId}`,
		);
	} catch (error) {
		return { url: null };
	}
};

export const enableSupportAccount = (
	organizationId: string
) =>
	useMutation<AxiosResponse, AxiosError>({
		mutationFn: () => fetcher.post(`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${organizationId}/support/user`, null),
	});

export const disableSupportAccount = (
	organizationId: string
) =>
	useMutation<AxiosResponse, AxiosError>({
		mutationFn: () => fetcher.delete(`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${organizationId}/support/user`),
	});

export const getSupportAccount = async (
	organizationId: string
) => {
	try {

		return await fetcher.get<User>(
			`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${organizationId}/support/user`,
		);
	} catch (error) {
		return null;
	}
};
