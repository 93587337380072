import { Auth } from '@aws-amplify/auth';
import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  disableSupportAccount,
  enableSupportAccount,
  getSupportAccount,
} from '$features/organizations';
import { OrganizationDetailRow } from '$features/organizations/detail/row/organization-detail-row';

/**
 * Renders the support account row in the organization detail page.
 * Only visible to users with the "SupportUser" profile in idToken payload.
 *
 * @param id   - The organization id.
 * @constructor
 */

export function OrganizationDetailRowSupportAccount({ id }: { id: string }) {
  const { t } = useTranslation();
  const {
    mutate: enableSupportAccountMutation,
    isLoading: isLoadingEnableSupportAccount,
    isSuccess: isSuccessEnableSupportAccount,
  } = enableSupportAccount(id);
  const {
    mutate: disableSupportAccountMutation,
    isLoading: isLoadingDisableSupportAccount,
    isSuccess: isSuccessDisableSupportAccount,
  } = disableSupportAccount(id);
  const [supportAccount, setSupportAccount] = React.useState<User | null>(null);
  const [isSupportUser, setIsSupportUser] = React.useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const checkSupportAccount = async () => {
    try {
      const session = await Auth.currentSession();
      const isSupport = session.getIdToken().payload.profile === 'SupportUser';
      setIsSupportUser(isSupport);
      if (isSupport) {
        const supportUser = await getSupportAccount(id);
        setSupportAccount(supportUser);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- wanted
      console.error(error);
    }
  };

  const handleOnConfirmationDialogOpen = () => {
    if (!supportAccount) {
      setConfirmationDialogOpen(true);
    } else {
      disableSupportAccountMutation();
    }
  };

  const handleOnSupportAccountChange = async () => {
    if (!supportAccount) {
      enableSupportAccountMutation();
    }

    setConfirmationDialogOpen(false);
  };

  useEffect(() => {
    checkSupportAccount();
  }, []);

  useEffect(() => {
    if (isSuccessEnableSupportAccount || isSuccessDisableSupportAccount) {
      checkSupportAccount();
    }
  }, [isSuccessDisableSupportAccount, isSuccessEnableSupportAccount]);

  if (!isSupportUser) {
    return null;
  }

  return (
    <>
      <OrganizationDetailRow
        label={t('organizationDetails.supportAccount')}
        value=""
        testId="support-account-section"
      >
        <Button
          className="support-account-toggle"
          variant="tertiary"
          size="small"
          onClick={handleOnConfirmationDialogOpen}
          disabled={isLoadingEnableSupportAccount || isLoadingDisableSupportAccount}
        >
          {supportAccount
            ? t('organizationDetails.disableSupportAccount')
            : t('organizationDetails.enableSupportAccount')}
        </Button>
      </OrganizationDetailRow>

      <Dialog isOpen={confirmationDialogOpen} hideCloseButton>
        <div className="flex flex__dir--column flex__ai--center">
          <Icon
            name="warning"
            className="bg-orange-500 m-bottom-xxl"
            size={IconSizeClasses.XLarge}
          />

          <div className="title">{t('inviteUsers.title')}</div>

          <div className="body-regular text-align-center m-y-s">
            {t('organizationDetails.disclaimerSupportAccount')}
          </div>
        </div>

        <Dialog.Actions>
          <Button onClick={() => setConfirmationDialogOpen(false)} variant="secondary">
            {t('inviteUsers.cancel')}
          </Button>
          <Button onClick={handleOnSupportAccountChange} variant="primary">
            {t('inviteUsers.confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
}
