import { useQuery } from '@tanstack/react-query';

import { fetcher } from '$shared/fetch';

import { environment } from 'src/environment';

// We want to keep the feat-toggle logic to be able to use it once we have a use case for it.
export type FeatureToggle = 'NO_FEAT_TOGGLE_USED_ATM_IN_BACKOFFICE';

export const fetchFeatureToggles = async (): Promise<Record<FeatureToggle, boolean>> => {
  const response = await fetcher.get<string[]>(
    '/application-configuration/backoffice/feature-toggles',
    environment.visionizeApiUrl,
  );
  const featureToggles = Object.fromEntries(response.map((flag) => [flag, true]));

  return featureToggles as Record<FeatureToggle, boolean>;
};

export const useFeatureToggles = (): Partial<Record<FeatureToggle, boolean>> => {
  const { data: featureToggles = {} } = useQuery(['featureToggles'], fetchFeatureToggles);

  return featureToggles;
};
